import { h } from 'preact';
import { Link } from 'preact-router/match';
import style from './style.css';

const Header = () => (
	<header class="main__header -front_page_header" id="main__header">
    <div class="container">
        <div class="menu_inner">
            <picture class="logo">
                <a href="https://dev.renergy.webd.pro">
                    <img src="https://dev.renergy.webd.pro/wp-content/uploads/2020/10/logo.png" alt="Respect Energy" title="Respect Energy" />
                </a>
            </picture>
            <nav class="menu">
                <div class="top_contact">
                    <a href="mailto:response@respect.energy"><svg xmlns="http://www.w3.org/2000/svg"  width="17px" height="12px">
                            <path fill-rule="evenodd" fill="rgb(63, 152, 124)" d="M0.877,11.645 L0.877,10.442 L6.107,7.042 L8.901,9.138 L11.696,7.042 L16.926,10.442 L16.926,11.645 L0.877,11.645 ZM16.926,3.119 L16.926,9.006 L12.721,6.273 L16.926,3.119 ZM0.877,1.615 L0.877,0.411 L16.926,0.411 L16.926,1.615 L8.901,7.633 L0.877,1.615 ZM0.877,9.006 L0.877,3.119 L5.082,6.273 L0.877,9.006 Z"></path>
                        </svg> response@respect.energy</a>
                    <a href="tel:+48799394358"><svg xmlns="http://www.w3.org/2000/svg"  width="12px" height="12px">
                            <path fill-rule="evenodd" fill="rgb(63, 152, 124)" d="M11.296,8.796 C10.873,7.980 9.402,7.095 9.337,7.056 C9.148,6.946 8.950,6.888 8.766,6.888 C8.491,6.888 8.267,7.016 8.131,7.249 C7.915,7.512 7.649,7.818 7.584,7.866 C7.083,8.212 6.690,8.173 6.256,7.731 L3.833,5.261 C3.401,4.821 3.362,4.417 3.699,3.909 C3.747,3.842 4.048,3.570 4.305,3.350 C4.469,3.251 4.582,3.103 4.631,2.922 C4.697,2.680 4.648,2.396 4.494,2.119 C4.457,2.056 3.588,0.556 2.788,0.125 C2.639,0.044 2.470,0.002 2.301,0.002 C2.023,0.002 1.762,0.112 1.565,0.312 L1.030,0.858 C0.183,1.721 -0.124,2.699 0.118,3.765 C0.320,4.653 0.908,5.598 1.865,6.574 L4.968,9.735 C6.180,10.971 7.336,11.597 8.403,11.597 C8.403,11.597 8.403,11.597 8.403,11.597 C9.188,11.597 9.919,11.257 10.577,10.587 L11.112,10.042 C11.437,9.710 11.511,9.210 11.296,8.796 Z"></path>
                        </svg> +48 799 394 358</a>


                    <div data-readabler-trigger="" class="access">


                        <svg xmlns="http://www.w3.org/2000/svg"  width="26px" height="26px">
                            <path fill-rule="evenodd" fill="rgb(63, 152, 124)" d="M13.250,25.1000 C6.208,25.1000 0.500,20.292 0.500,13.250 C0.500,6.208 6.208,0.500 13.250,0.500 C20.292,0.500 25.1000,6.208 25.1000,13.250 C25.1000,20.292 20.292,25.1000 13.250,25.1000 ZM22.154,12.822 C19.839,9.752 16.702,7.864 13.243,7.864 C9.784,7.864 6.647,9.752 4.332,12.818 C4.143,13.069 4.143,13.420 4.332,13.671 C6.647,16.741 9.784,18.629 13.243,18.629 C16.702,18.629 19.839,16.741 22.154,13.675 C22.342,13.424 22.342,13.073 22.154,12.822 ZM13.491,17.037 C11.195,17.181 9.298,15.293 9.443,12.999 C9.561,11.108 11.098,9.574 12.995,9.456 C15.291,9.312 17.187,11.200 17.043,13.494 C16.920,15.382 15.383,16.915 13.491,17.037 ZM13.113,11.204 C12.091,11.270 11.261,12.094 11.198,13.113 C11.117,14.347 12.139,15.363 13.376,15.286 C14.395,15.223 15.224,14.399 15.291,13.376 C15.372,12.142 14.350,11.126 13.113,11.204 Z"></path>
                        </svg>



                    </div>


                </div>
                <div class="menu d-flex">
                    <ul id="menu-menu-glowne-pl-1" class="menu"><li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-138136"><a href="https://dev.renergy.webd.pro/o-nas/">o nas</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page current-menu-ancestor current-menu-parent current_page_parent current_page_ancestor menu-item-has-children menu-item-142216"><a href="https://dev.renergy.webd.pro/oferta/">oferta</a>
<ul class="sub-menu">
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142098"><a href="https://dev.renergy.webd.pro/oferta/dla-wytworcy/">odkup energii</a></li>
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142099"><a href="https://dev.renergy.webd.pro/oferta/dla-odbiorcy/">sprzedaż energii dla firm</a></li>
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142882"><a href="https://dev.renergy.webd.pro/oferta/fotowoltaika-dla-firm/">fotowoltaika dla firm</a></li>
	<li class="special menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-128094 current_page_item menu-item-143176"><a href="https://dev.renergy.webd.pro/formularz-zamowienia/" aria-current="page">podpisz umowę online</a></li>
</ul>
</li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142210"><a href="https://dev.renergy.webd.pro/inwestycje-respect-energy/">inwestycje</a></li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-143016"><a href="https://dev.renergy.webd.pro/artykuly/">artykuły</a>
<ul class="sub-menu">
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142048"><a href="https://dev.renergy.webd.pro/artykuly/news/">aktualności respect energy</a></li>
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143012"><a href="https://dev.renergy.webd.pro/artykuly/blog/">blog</a></li>
</ul>
</li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-has-children menu-item-142036"><a href="https://dev.renergy.webd.pro/kariera/">kariera</a>
<ul class="sub-menu">
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142546"><a href="https://dev.renergy.webd.pro/kariera/aktualne-oferty/">aktualne oferty pracy</a></li>
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143207"><a href="https://dev.renergy.webd.pro/kariera/proces-rekrutacyjny/">proces rekrutacyjny</a></li>
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-143200"><a href="https://dev.renergy.webd.pro/kariera/benefity/">benefity</a></li>
	<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-142549"><a href="https://dev.renergy.webd.pro/kariera/tak-pracujemy/">tak pracujemy</a></li>
</ul>
</li>
<li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-74"><a href="https://dev.renergy.webd.pro/kontakt/">kontakt</a></li>
<li class="cta-menu menu-item menu-item-type-post_type menu-item-object-page current-menu-item page_item page-item-128094 current_page_item menu-item-143510"><a href="https://dev.renergy.webd.pro/formularz-zamowienia/" aria-current="page">podpisz umowę online</a></li>
</ul>
                    <ul id="menu-menu-jezykow" class="languages_menu"><li id="menu-item-9459-en" class="lang-item lang-item-10 lang-item-en no-translation lang-item-first menu-item menu-item-type-custom menu-item-object-custom menu-item-9459-en"><a href="https://dev.renergy.webd.pro/en/" hreflang="en-GB" lang="en-GB">en</a></li>
</ul>


                </div>




            </nav>

        </div>
    </div>

    


</header>
);

export default Header;
